<template>
  <div class="bg-f">
    <!--公共头部-->
    <Header></Header>
    <!-- 用户中心头部 -->
    <user-header />
    <!-- 内容 -->
    <div class="main-content">
      <div class="container">
        <div class="accout-content clearfix" style="display:flex">
          <!-- 左边 -->
          <user-left mark="collect" :userId="userId" />
          <!-- 右边 -->
          <div class="right">
            <div class="ringo" v-for="(item,index) in list" :key="index">
              <div class="rimg">
                <img :src="item.thumb" alt="">
              </div>
              <div class="rtxt">
                <div class="rtxt1">{{item.productName}}</div>
                <div class="rtxt2">
                  <div class="rleft2">{{ $t('FOBPrice') }}:</div>
                  <div class="rright2">{{item.minPrice}} {{item.maxPrice}}</div>
                </div>
                <div class="rtxt3">
                  <div class="rleft3">{{ $t('Min.Order') }}:</div>
                  <div class="rright3">{{item.minOrder}}</div>
                </div>
                <div class="rtxt4">
                  <div class="rleft4">{{ $t('categoryName') }}:</div>
                  <div class="rright4">{{item.categoryOneName}}</div>
                </div>
                <div class="rtxt3">
                  <div class="rleft3">{{ $t('Keywords') }}:</div>
                  <div class="rright3">{{item.keywords}}</div>
                </div>
                <div class="rtxt4">
                  <div class="rleft4">{{ $t('PaymentMethod') }}:</div>
                  <div class="rright4">{{item.payMethod}}</div>
                </div>
              </div>
            </div>
            <!-- 分页 -->
            <div class="page">
              <a-pagination
                :hideOnSinglePage="true"
                showQuickJumper
                :defaultCurrent="pagination.defaultCurrent"
                :defaultPageSize="pagination.defaultPageSize"
                :total="pagination.total"
                @change="onChange"
                @showSizeChange="onShowSizeChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 公共尾部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import UserHeader from './components/Header'
import UserLeft from './components/Left'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Editor from '@/components/Editor/TinyMceEditor'

export default {
  name: 'UsersCollection',
  components: {
    UserHeader,
    Footer,
    UserLeft,
    Editor,
    Header
  },
  data () {
    return {
      userId: this.$route.params.userId,
      list: [],
      page: 1,
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        defaultCurrent: 1, // 默认当前页数
        defaultPageSize: 8, // 默认当前页显示数据的大小
        total: 0 // 总数，必须先有
      },
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
     // 获取行业列表
    getList () {
       const params = {
            page: this.pagination.defaultCurrent,
            pagesize: this.pagination.defaultPageSize
       }
       this.$common.fetchList('/user/product/likes', Object.assign({}, params)).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.list = result.lists
          const pagination = { ...this.pagination }
          pagination.total = result.totalInfo.total_items
          this.pagination = pagination
        }
      })
    },
     //分页
    onShowSizeChange (current, pageSize) {
      this.pagination.defaultCurrent = 1
      this.pagination.defaultPageSize = pageSize
      this.getList() // 显示列表的接口名称
    },
    onChange(current, size) {
      this.pagination.defaultCurrent = current
      this.pagination.defaultPageSize = size
      // 查数据
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/colect.less';
</style>